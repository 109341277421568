<template>
  <div>
    <vx-card>
      <h2 class="ml-2 font-semibold mb-2">
        {{ $t(resources.LyraBinaryTree.i18n) || resources.LyraBinaryTree.name }}
      </h2>

      <div class="vx-row mt-2">
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="
              $t(resources.DistributorID.i18n) || resources.DistributorID.name
            "
            v-model="itaCodeForm"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label="$t(resources.UntilLevel.i18n) || resources.UntilLevel.name"
            v-model="untilLevel"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-5">
          <vs-button
            color="primary"
            type="filled"
            :disabled="!validateForm"
            @click="search()"
            >{{ $t(resources.Search.i18n) || resources.Search.name }}</vs-button
          >
        </div>
      </div>

      <vs-row>
        <tree :tree-data="tree"></tree>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import ranks from "@/assets/resources/ranks.js";
import Tree from "./tree";
import alerts from "@/i18n/alerts.js";

export default {
  components: {
    Tree
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      itadist: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      itaCodeForm: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      fromLevel: 0,
      untilLevel: 4,
      ranks: ranks,
      tree: { status: "" },
      isDownline: 0
    };
  },
  created: function() {
    this.search();
  },
  computed: {
    validateForm() {
      if (this.untilLevel > 10) {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgNotAllow10Rows"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle"
        });
      }

      return !this.errors.any() && this.untilLevel <= 10;
    },

    search() {
      this.tree = {
        id: 0,
        title: "1430",
        dist: "1430",
        level: "0",
        name: "Gomez Sepulveda, Ana Maria",
        endRank: "Platino",
        endRankId: "4",
        vol3: "0.00",
        vol1: "0.00",
        bus_ctr: "1",
        status: "S",
        side: "R",
        expanded: true,
        children: [
          {
            id: 2,
            dist: 1430,
            bus_ctr: 2,
            endRankId: "4",
            vol3: "0.00",
            vol1: "0.00",
            status: "D",
            children: [
              {
                id: 4,
                dist: 1450,
                bus_ctr: 1,
                endRankId: "4",
                vol3: "0.00",
                vol1: "0.00",
                status: "D",
                children: [
                  {
                    id: 5,
                    dist: 1450,
                    bus_ctr: 2,
                    endRankId: "4",
                    vol3: "0.00",
                    vol1: "0.00",
                    status: "D",
                    children: [
                      {
                        id: 8,
                        dist: 1480,
                        bus_ctr: 1,
                        endRankId: "4",
                        vol3: "0.00",
                        vol1: "0.00",
                        status: "D",
                        children: []
                      }
                    ]
                  },
                  {
                    id: 6,
                    dist: 1450,
                    bus_ctr: 3,
                    endRankId: "4",
                    vol3: "0.00",
                    vol1: "0.00",
                    status: "D",
                    children: []
                  }
                ]
              }
            ]
          },
          {
            id: 3,
            dist: 1430,
            bus_ctr: 3,
            endRankId: "4",
            vol3: "0.00",
            vol1: "0.00",
            status: "D",
            children: [
              {
                id: 7,
                dist: 1460,
                bus_ctr: 1,
                endRankId: "4",
                vol3: "0.00",
                vol1: "0.00",
                status: "D",
                children: []
              }
            ]
          },
          // {
          //   id: 9,
          //   dist: 1430,
          //   bus_ctr: 1,
          //   endRankId: "4",
          //   vol3: "0.00",
          //   vol1: "0.00",
          //   status: "D",
          //   children: []
          // }
        ]
      };
    }
  },
  methods: {}
};
</script>
<style>
@import "../../assets/css/tree.css";
</style>
